<section class="page_404">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 ">
          <div class="col-sm-12  text-center">
            <div class="four_zero_four_bg">
              <h1 class="text-center ">404</h1>
              <h1 class="text-center ">Page not found</h1>
            </div>
            <div class="contant_box_404">
                <div fxFlex class="pl-10">
                    <button class="btn-dark">Redirecting in <span>{{redirectTiming}}</span> </button>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>