import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './auth-guard.service';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
    data: {
      title: 'Due Factory - Credit Score Improvement | Credit Repair | Debt Relief Platform',
      description:'Check credit score, Settle your old outstanding loans, follow our recommendations and boost credit score with Due Factory. Pay in full or in parts.',
    } 
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
    data: {
      title: 'Due Factory - Credit Score Improvement | Credit Repair | Debt Relief Platform',
      description:'Check credit score, Settle your old outstanding loans, follow our recommendations and boost credit score with Due Factory. Pay in full or in parts.',
    } 
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuardService],
    data: {
      title: 'Due Factory - Credit Score Improvement | Credit Repair | Debt Relief Platform',
      description:'Check credit score, Settle your old outstanding loans, follow our recommendations and boost credit score with Due Factory. Pay in full or in parts.',
    } 

  },
  { 
    path: '**',
    pathMatch: 'full',  
    component: PageNotFoundComponent
   }, 
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
