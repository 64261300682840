import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoaderService } from './loader.service';
import { finalize } from 'rxjs/operators';

@Injectable()
export class HttpInterceptorInterceptor implements HttpInterceptor {

  constructor(private loader: LoaderService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.loader.showLoader();
    const token = localStorage.getItem('token');
    if(token && request.url.indexOf("blogDetailByTitle") === -1 && request.url.indexOf("blogListingForWeb") === -1 ){
      request = request.clone(
        {
          setHeaders: {
            'Authorization': 'Bearer '+token
          }
        }
      );
    }
    
    return next.handle(request).pipe(
      finalize(() => this.loader.hideLoader())
    );
  }
}
