import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Location } from '@angular/common';


@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {

  constructor(private location: Location, private router: Router) { }

  redirectTiming = 5;

  ngOnInit(): void {
    let clearTimer = setInterval(() => {
      this.redirectTiming--;
      if (this.redirectTiming == 0) {
        this.router.navigate(['/'])
      }
    }, 1000);

  }

}
