import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, TemplatePortal } from '@angular/cdk/portal';
import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  ViewContainerRef,
  Input,
} from '@angular/core';
import { LoaderService } from 'src/app/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {
  constructor(public loader: LoaderService) {}
  ngOnInit() {}
}
